import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { api_url } from "../config";
import { useSelector } from "react-redux";
import { selectAuthData } from "./AuthSlice";






export const fetchBranches=createAsyncThunk("fetchBranches", 
async (_,{getState})=>{
    const authData = selectAuthData(getState());
    try { 
        const response = await axios.get(api_url + `/api/wholesaler/admin/${authData.id}/branch`);
        return response.data;
        
    } catch (error) {
        throw new Error('Failed to fetch data');
    }
}

)

const branchSlice = createSlice({
    name:'branches',
    initialState:{
        data:null,
        isloading:false,
        error: false,
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchBranches.pending, (state) => {
            state.isloading = true;
            state.error = null;
          })
          .addCase(fetchBranches.fulfilled, (state, action) => {
            state.isloading = false;
            state.data = action.payload;
          })
          .addCase(fetchBranches.rejected, (state, action) => {
            state.isloading = false;
            state.error = action.error.message;
          });
      }

})


export const selectBranchData = (state) => state.branchInfo;
export const branchesReducer = branchSlice.reducer;