import React from 'react';
import RetailOverview from '../../components/retailLib/RetailOverview';

const RetailAdminDashboard = () => {
    return (
        <div className='flex gap-4'>
            <RetailOverview/>
        </div>
    );
}

export default RetailAdminDashboard;
