import React from 'react';
import RetailBranches from '../../components/retailLib/RetailBranches';

const RetailBranchesPage = () => {
    return (
        <div>
            <RetailBranches/>
        </div>
    );
}

export default RetailBranchesPage;
