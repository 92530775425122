import React, { useState } from 'react';
import { IoEyeOutline } from 'react-icons/io5';
import { FaRegEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Hourglass } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthData } from '../../Redux/AuthSlice';
import { api_url } from '../../config';
const LoginForm = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordState, setPasswordState] = useState('')
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false);

    const handlePasswordToggle = (e) => {
        e.preventDefault();
        setPasswordState(!passwordState);
    };
    const navigate = useNavigate();
    const dispatch = useDispatch()
  
    const handleLogin = async (e) => {
        e.preventDefault();
        const loginForm = {
            email,
            password
        }
        try {
            setLoading(true);
            const response = await axios.post(api_url+'/api/admin/login', loginForm, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            // console.log(response.data);
            dispatch(setAuthData(response.data))
            navigate('/generate-token')
        } catch (error) {
            setError(error?.response?.data?.error)
            //console.log(error?.response?.data?.error);
            setTimeout(() => {
                setError(null);
            }, 6000);
        } finally {
            setLoading(false);
        }
    }
    const auth = useSelector((state)=>state.auth)
    
    return (
       <div className='flex items-center justify-center h-[80vh] sm:h-screen w-full bg-slate-100'>
         <div className='w-full md:w-[40vw] xl:w-[30vw]  bg-white rounded-xl shadow-lg'>
          
          
          <form className='flex flex-col gap-4 m-6' >
          <div className=' '>
              <p className='text-xs'>Sign In</p>
          </div>
              <div>
                  <label
                      htmlFor='email'
                      className='block mb-2 text-sm font-medium font-lufga text-gray-900 '
                  >
                      Email
                  </label>
                  <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type='email'
                      name='email'
                      id='email'
                      className='bg-gray-50 border border-gray-300 text-gray-900 text-xs sm:text-sm rounded-lg focus:outline-none  block w-full p-2.5 '
                      placeholder='admin@provider.com'
                      required
                  />
              </div>
              <div>
                  <label
                      htmlFor='password'
                      className='block mb-2 text-sm font-medium font-lufga text-gray-900 '
                  >
                      Password
                  </label>
                  <div className='flex justify-between items-center bg-gray-50 border text-xs border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600  w-full p-2.5 '>
                      <input
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          type={passwordState ? 'text' : 'password'}
                          name='password'
                          placeholder='••••••••'
                          className='bg-gray-50 focus:outline-none w-full'
                          required
                      />
                      {passwordState ? (
                          <IoEyeOutline size={20} onClick={handlePasswordToggle} />
                      ) : (
                          <FaRegEyeSlash size={20} onClick={handlePasswordToggle} />
                      )}
                  </div>
              </div>
              <div className='flex justify-end'>
              <Link to='/internal-admin-forgot-password'>
                  <p className='hover:underline font-lufga cursor-pointer text-xs text-blue-800'>Forgot Password?</p>
                  </Link>
              </div>
              {error && (
                  <div>
                      <p className="bg-red-300 rounded-md font-lufga shadow-md px-2 py-1 text-xs">
                          {typeof error === 'string' ? error : (
                              Object.keys(error).map((key) => (
                                  Array.isArray(error[key]) ? error[key][0] : error[key]
                              ))
                          )}
                      </p>
                  </div>
              )}

             <div className='flex sm:justify-end'>
             <button
                  onClick={handleLogin}
                // onClick={()=>{navigate('/generate-token')}}
                  className='w-full sm:w-fit  font-lufga text-white bg-blue-500 hover:opacity-90  focus:outline-none rounded-md shadow-md text-xs sm:text-sm px-4 py-2 sm:px-10 sm:py-3 text-center '
              >

                  Sign In
              </button>
             </div>
              {loading ? (<div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                  <Hourglass
                      visible={true}
                      height="40"
                      width="40"
                      ariaLabel="hourglass-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      colors={['#306cce', '#72a1ed']}
                  />
              </div>) : null}
            
          </form>
          <div className='flex justify-center border-t py-2 px-4'>
                  <Link to='/internal-admin-signup'>
                      <p className='text-xs'>Don't have an account? <span className='hover:underline font-lufga cursor-pointer text-blue-800'>Sign Up</span></p>
                  </Link>
              </div>
      </div>
       </div>
    );
}

export default LoginForm;
