import React from 'react';
import ForgotRetailPassword from '../../components/retailLib/forgotRetailPassword';

const ForgotRetailPasswordPage = () => {
    return (
        <div className=' flex justify-center items-center bg-gray-50 h-screen w-full '>
            <ForgotRetailPassword/>
        </div>
    );
}

export default ForgotRetailPasswordPage;
