import React from 'react';
import Branches from '../../components/wholesaleLib/branches';
import { Outlet } from 'react-router';

const BranchesPage = () => {
    return (
        <div className='h-screen w-full'>
            <Branches/>
        </div>
    );
}

export default BranchesPage;
