import React from 'react';
import LoginForm from '../components/loginForm';

const LoginPage = () => {
    return (
        <div className=' flex justify-center items-center bg-gray-50 h-screen w-full '>
            <LoginForm/>
        </div>
    );
}

export default LoginPage;
