import React from 'react';

const StatusHistory = ({ user, users }) => {
    // Find the user with the matching id from the users list
    const currentUser = users.find(u => u.id === user.id);
    return (
        <div className='max-w-2xl h-[55vh] sm:h-[55vh] overflow-auto sm:min-w-[300px] lg:min-w-[600px] mx-auto p-4'>
            <div className=' mb-6'>
                <p className='font-lufga text-center font-semibold text-neutral-500'>User's Status history</p>
            </div>
            {currentUser && currentUser.status_history.length > 0 ? (
                <table className='w-full text-xs 2xl:text-sm px-2 table-auto overflow-auto'>
                    <thead className='bg-neutral-200  sticky top-0'>
                        <tr>
                            <th >No.</th>
                            <th >Date changed</th>
                            <th >Status</th>
                        </tr>
                    </thead>
                    <tbody className='py-2'>
                        {currentUser.status_history.map((data, index) => (
                            <tr key={data.id} className={index % 2 === 0 ? 'bg-gray-100 hover:bg-slate-50' : 'bg-white hover:bg-slate-50'}>
                                <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{index + 1}</td>
                                <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{data?.changed_at}</td>
                                <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{data?.status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-gray-500 text-center text-xs">No status history</p>
            )}
        </div>
    );
}

export default StatusHistory;
