import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import { api_url } from '../../config';
import api from '../../utils/api';
import { toast } from 'react-toastify';

const UpdatePassword = ({ onCancel }) => {
  const { id, token } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [showPasswords, setShowPasswords] = useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = (field) => {
    setShowPasswords({ ...showPasswords, [field]: !showPasswords[field] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.newPassword !== formData.confirmNewPassword) {
      setError("New passwords don't match");
      return;
    }
    try {
      setIsLoading(true);
      setError('');
      setSuccess('');
      await api.put(
        api_url + `/api/v1/wholesaler/admin/${id}/update-profile-password`,
        {
          old_password: formData.currentPassword,
          password: formData.newPassword,
          password_confirmation: formData.confirmNewPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSuccess('Password updated successfully');
      toast.success('Password updated successfully')
      // Reset form after successful update
      setFormData({ currentPassword: '', newPassword: '', confirmNewPassword: '' });
    } catch (error) {
      setError( 'Failed to update password');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
      <div className="w-full max-w-md rounded-xl shadow-xl bg-white overflow-hidden">
        <div className="bg-gradient-to-r from-blue-300 to-blue-700 text-white py-4 px-6">
          <h2 className="text-2xl font-semibold">Update Password</h2>
        </div>
        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          <PasswordField
            name="currentPassword"
            label="Current Password"
            value={formData.currentPassword}
            onChange={handleChange}
            showPassword={showPasswords.currentPassword}
            toggleVisibility={() => togglePasswordVisibility('currentPassword')}
          />
          <PasswordField
            name="newPassword"
            label="New Password"
            value={formData.newPassword}
            onChange={handleChange}
            showPassword={showPasswords.newPassword}
            toggleVisibility={() => togglePasswordVisibility('newPassword')}
          />
          <PasswordField
            name="confirmNewPassword"
            label="Confirm New Password"
            value={formData.confirmNewPassword}
            onChange={handleChange}
            showPassword={showPasswords.confirmNewPassword}
            toggleVisibility={() => togglePasswordVisibility('confirmNewPassword')}
          />
          {error && <p className="text-red-500 text-xs">{error}</p>}
          {success && <p className="text-green-500 text-xs">{success}</p>}
          <div className="flex justify-end space-x-4 mt-6">
            <button
              type="button"
              onClick={onCancel}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition duration-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
              disabled={isLoading}
            >
              {isLoading ? 'Updating...' : 'Update Password'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const PasswordField = ({ name, label, value, onChange, showPassword, toggleVisibility }) => (
  <div className="relative">
    <label htmlFor={name} className="text-sm text-gray-600 mb-1 block">
      {label}
    </label>
    <div className="flex items-center border rounded-md">
      <span className="text-gray-500 px-3">
        <FaLock />
      </span>
      <input
        type={showPassword ? "text" : "password"}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        className="flex-grow p-2 rounded-r-md focus:outline-none "
        required
      />
      <button
        type="button"
        onClick={toggleVisibility}
        className="absolute right-3 text-gray-500 focus:outline-none"
      >
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </button>
    </div>
  </div>
);

export default UpdatePassword;