import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { toast } from 'react-toastify';
import api from '../utils/api';
import { api_url } from '../config';
import { clearAuthData, logoutState } from '../Redux/AuthSlice';

const VerifyEmailModal = () => {
    const { product_name, email, token, loader } = useSelector((state) => state.auth);
    const [error, setError] = useState('');
    const [status, setStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setTimeout(() => setCountdown(countdown - 1), 1000);
        } else if (countdown === 0 && btnDisabled) {
            setBtnDisabled(false);
        }
        return () => clearTimeout(timer);
    }, [countdown, btnDisabled]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setBtnDisabled(true);
        setCountdown(60);

        const apiEndpoint = product_name === 'pharmtrix-retail' 
            ? '/api/retailer/admin/email/verification-notification'
            : '/api/wholesaler/admin/email/verification-notification';

        try {
            setIsLoading(true);
            await api.post(api_url + apiEndpoint, email, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            });
            setStatus('Email verification link has been sent');
            toast.success('Email verification link has been sent');
            setTimeout(() => setStatus(''), 6000);
        } catch (error) {
            console.error(error);
            setError(error?.response?.data?.error || 'An error occurred');
            setTimeout(() => setError(''), 6000);
        } finally {
            setIsLoading(false);
        }
    };

    const handleLogout = async (e) => {
        e.preventDefault();
        const logoutEndpoint = product_name === 'pharmtrix-retail'
            ? '/api/retailer/admin/logout'
            : '/api/wholesaler/admin/logout';

        try {
            dispatch(logoutState(true));
            await axios.post(api_url + logoutEndpoint, {}, {
                headers: { Authorization: `Bearer ${token}` },
            });
            dispatch(clearAuthData());
            window.history.pushState(null, '', '/');
            window.onpopstate = () => {
                window.history.pushState(null, '', '/');
            };
            dispatch(logoutState(false));
            navigate('/', { replace: true });
        } catch (error) {
            console.error('Logout error:', error);
            toast.error('Logout failed. Please try again.');
        }
    };

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
                <div className="fixed inset-0 bg-neutral-800 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <div className="relative bg-white rounded-lg p-8 max-w-md w-full mx-auto">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-lg font-bold text-gray-900">Email Verification Required</h2>
                    </div>
                    <p className="text-gray-700 mb-4">You need to verify your email address before proceeding. Kindly click on the "send email" button below to receive verification link in your email.</p>
                    <div className="flex justify-end mb-4">
                        <button onClick={handleLogout} className="bg-neutral-500 hover:bg-neutral-600 text-sm text-white shadow-md font-semibold py-2 px-4 rounded focus:outline-none mr-2">
                            {loader ? 'Logging out...' : 'Logout'}
                        </button>
                        <button 
                            onClick={handleSubmit} 
                            disabled={btnDisabled}
                            className={`${btnDisabled ? 'bg-blue-200' : 'bg-blue-500 hover:bg-blue-600'} text-white font-bold py-2 px-4 rounded focus:outline-none mr-2`}
                        >
                            {isLoading ? 'Sending...' : `Send Email ${countdown > 0 ? `(${countdown}s)` : ''}`}
                        </button>
                    </div>
                    {status && (
                        <div>
                            <p className="bg-green-300 rounded-md shadow-md px-2 py-1 text-xs text-center">{status}</p>
                        </div>
                    )}
                    {error && (
                        <div>
                            <p className="bg-red-300 rounded-md text-center shadow-md px-2 py-1 text-sm">
                                {typeof error === 'string'
                                    ? error
                                    : Object.keys(error).map((key) =>
                                        Array.isArray(error[key]) ? error[key][0] : error[key]
                                    )}
                            </p>
                        </div>
                    )}   
                </div>
            </div>
        </div>
    );
}

export default VerifyEmailModal;