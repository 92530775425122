import React, { useEffect, useRef, useState } from 'react';
import { CiMenuKebab } from "react-icons/ci";
import { useDispatch, useSelector } from 'react-redux';
import { MdClose } from 'react-icons/md';
import { FaRegEye } from "react-icons/fa";
import { GrEdit } from "react-icons/gr";
import { IoArchiveOutline } from "react-icons/io5";
import { api_url } from '../../config';
import axios from 'axios';
import { InfinitySpin } from 'react-loader-spinner';
import { Link, useNavigate } from 'react-router-dom';
import { setBranchData } from '../../Redux/BranchDetailsSlice';
import EditRetailBranch from './EditRetailBranch';
import AddRetailBranchForm from './AddRetailBranchForm';
import api from '../../utils/api';

const RetailBranches = () => {
    const { name, id, token } = useSelector((state) => state.auth);
    const [toggle, setToggle] = useState(false);
    const [branches, setBranches] = useState([])
    const [error, setError] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [isPopUpVisible, setPopUpVisible] = useState(false)
    const [selectedDropdown, setSelectedDropdown] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedBranchId, setSelectedBranchId] = useState(null);
    const [loading, setLoading] = useState(true)
    const [searchQuery, setSearchQuery] = useState('');



    const modalRef = useRef();
    const dispatch = useDispatch()
    const fetchBranches = async () => {

        try {
            setLoading(loading)
            const response = await api.get(api_url + `/api/v1/retailer/admin/${id}/branch`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            setBranches(response.data.data);
            dispatch(setBranchData(response.data.data))
        } catch (error) {
            setError(error);
            console.log(error);
        } finally {
            setLoading(false)
        }
    };

    
  
    

    useEffect(() => {
        fetchBranches();
    }, [id, token]);

    useEffect(() => {
        // Function to handle click outside of modal
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowModal(false);
                setShowEditModal(false)
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const toggleAddBranch = () => {

        setShowModal(!showModal)
    };
    const toggleEditModal = (event) => {
        if (event) {
            event.preventDefault(); // Prevent default behavior
            event.stopPropagation();
        }
        setShowEditModal(!showEditModal);
        setSelectedDropdown(false);
    }
    const togglePopUp = (event, index, branch) => {
        event.stopPropagation();
        event.preventDefault();
        if (index === selectedDropdown) {
            setSelectedDropdown(null);
            setSelectedBranch(null); // Deselect branch if clicking on the same dropdown
        } else {
            setSelectedDropdown(index);
            setSelectedBranch(branch); // Set the selected branch
        }
    };



    const popUpRef = useRef();

    useEffect(() => {
        // Function to handle click outside of modal
        const handleClickOutside = (event) => {
            if (popUpRef.current && !popUpRef.current.contains(event.target)) {
                setSelectedDropdown(null);
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
   

    const navigate = useNavigate()
    // const toBranchInfo = (branchId) => {
    //     navigate(`/branch-info/${branchId}`);
    // };

    const searchBranches = branches.filter((branch)=>(
        branch.name.toLowerCase().startsWith(searchQuery.toLowerCase())
    ))

 
    return (
        <div>
        {loading && ( // Display loader while loading
            <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                <InfinitySpin color="#059212" loading={loading} size={40} />
            </div>
        )}

        {!loading && ( // Render content when not loading
            <div className={branches.length === 0 ? 'pt-0' : 'pt-10'}>
                <div className={branches.length === 0 ? 'hidden' : 'flex flex-col gap-4 mb-4 sm:flex-row justify-between items-center font-lufga mx-6'}>
                    <div>
                        <p className='text-sm'>Branch summary information</p>
                    </div>
                    <div className='flex flex-col sm:flex-row items-center gap-4'>
                        <div>
                            <input type='text' placeholder='Search branches' value={searchQuery} onChange={(e)=>{setSearchQuery(e.target.value)}} className='w-38 sm:w-[200px] focus:outline-none rounded-md shadow-sm border px-2 py-3 h-3 font-lufga text-xs' />
                        </div>
                        <div>
                            <button onClick={toggleAddBranch} className="bg-green-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs">Add Branch</button>
                        </div>
                    </div>
                </div>
                {branches.length > 0 ? (
                    <div className='w-full flex flex-wrap gap-4 px-[2px] justify-center sm:justify-normal items-center  pt-8 pb-14 max-h-[85vh] overflow-y-auto'>

                        {searchBranches.map((item) => (
                            <div key={item.id}>
                              
                                    <div onClick={()=>navigate(`branch-info`,{state:{item}})} className='flex justify-center items-start relative'>
                                   
                                        <div className={`${item.status === 'inactive' ? 'bg-gradient-to-br from-gray-100 to-gray-300 border-[1px] text-gray-900 border-gray-500':'bg-gradient-to-br from-green-100 to-green-300 border-[1px] text-green-900 border-green-500'} border-[1px] w-[230px] hover:scale-105 duration-150 cursor-pointer ease-in h-[75px] rounded-lg  shadow-lg p-2 flex gap-2`}>
                                            <div>
                                                <p className='text-sm w-[190px] truncate hover:text-clip font-lufga'>{item.name}</p>
                                                <p className='text-xs w-[190px] truncate hover:text-clip'>Address: <span>{item.address}</span></p>
                                                <p className='text-xs w-[190px] truncate hover:text-clip'>Contact: <span>{item.phone}</span></p>
                                            </div>
                                            <CiMenuKebab onClick={(event) => togglePopUp(event, item.id, item)} className='text-neutral-700 cursor-pointer ' />
                                        </div>
                                        {selectedDropdown === item.id && (
                                            <div ref={popUpRef} className='absolute flex flex-col z-10 gap-2 top-8 right-0 bg-white border rounded shadow-md min-h-max p-2 min-w-max w-20'>
                                                <div className='cursor-pointer justify-center  hover:bg-slate-50 flex gap-2 items-center'>
                                                    <FaRegEye />
                                                    <p className=' text-xs sm:text-sm ' >
                                                        View
                                                    </p>
                                                </div>
                                                <div onClick={(event) => toggleEditModal(event)} className='cursor-pointer justify-center  hover:bg-slate-50 flex gap-2 items-center'>
                                                    <GrEdit />
                                                    <p className=' text-xs sm:text-sm' >
                                                        Edit
                                                    </p>
                                                </div>
                                                {/* <div className='cursor-pointer justify-center hover:bg-slate-50 flex gap-2 items-center'>
                                                    <IoArchiveOutline />
                                                    <p className=' text-xs sm:text-sm' >
                                                        Archive
                                                    </p>
                                                </div> */}
                                                {/* Add other dropdown items as needed */}
                                            </div>
                                        )}
                                    </div>
                                
                                {/* editing branches here */}

                                {
                                    showEditModal && (

                                        <div className="fixed inset-0 flex items-center justify-center bg-neutral-800 bg-opacity-10">
                                            <div ref={modalRef} className="bg-white rounded-md min-h-max shadow-lg p-6">
                                                <MdClose size={24} onClick={toggleEditModal} className='hover:scale-105 hover:text-gray-800 transition duration-150 ease-out cursor-pointer ' />
                                                <EditRetailBranch toggle={toggleEditModal} fetchBranches={fetchBranches} branches={branches} branchDetails={selectedBranch} />
                                            </div>
                                        </div>

                                    )
                                }
                            </div>


                        ))}
                    </div>
                ) : (
                    <div className="flex flex-col items-center justify-center font-lufga h-screen">
                        <p className="text-gray-500 text-center text-xs">Welcome! Get Started</p>
                        <button onClick={toggleAddBranch} className="bg-green-500  hover:opacity-90 text-white py-2 px-4 rounded-sm shadow-md mt-4">Add Branch</button>
                    </div>
                )}

                {showModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                        <div ref={modalRef} className="bg-white rounded-md overflow-auto max-h-[90vh] shadow-lg p-6">
                            <MdClose size={24} onClick={toggleAddBranch} className='hover:scale-105 hover:text-gray-800 transition duration-150 ease-out cursor-pointer ' />
                            <AddRetailBranchForm toggle={toggleAddBranch} fetchBranches={fetchBranches} />
                        </div>
                    </div>
                )}


            </div>
        )}

    </div>
    );

}
export default RetailBranches;
