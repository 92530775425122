import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Chart as ChartJS } from 'chart.js/auto';
import { Bar, Doughnut } from 'react-chartjs-2'
import axios from 'axios';
import { api_url } from '../../config';
import { InfinitySpin } from 'react-loader-spinner';
import api from '../../utils/api';

const RetailOverview = () => {
    const { name, email, contact, company_name, product_name, id, token } = useSelector((state) => state.auth)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()


    const fetchOverview = async () => {

        try {
            setLoading(loading)
            const response = await api.get(api_url + `/api/v1/retailer/admin/${id}/overview`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            setData(response.data.data);
            // dispatch(setBranchData(response.data.data))
            console.log(response.data.data);

        } catch (error) {
            setError(error);
            console.log(error);
        } finally {
            setLoading(false)
        }
    };


    const UsersChartData = {
        labels: ['Active Users', 'Inactive Users', 'Archived Users'],
        datasets: [{
            data: [data?.totalActive, data?.totalInactive, data?.totalArchive],
            backgroundColor: ['#06D001', '#FF6384', '#373A40'],
        }]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'User Status Distribution'
            }
        }
    };
    const BranchesChartData = {
        labels: ['Active Branches', 'Inactive Branches'],
        datasets: [{
            data: [data?.activeBranches, data?.inactiveBranches],
            backgroundColor: ['#06D001', '#FF6384'],
        }]
    };

   


    useEffect(() => {
        fetchOverview()
    }, [id, token])

    return (
        <div className='w-full mx-8'>
            {loading && ( // Display loader while loading
                <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                    <InfinitySpin color="#059212" loading={loading} size={40} />
                </div>
            )}
            <div className='my-8 mx-8 '>
                <p className='lg:text-3xl text-xl font-lufga font-semibold text-slate-600'>Overview</p>
            </div>
            {!loading && 
            <div className='flex flex-col lg:flex-row w-full items-center justify-center gap-8 mb-4'>
                <div className={`w-full h-full bg-gradient-to-br from-blue-100 to-green-300  text-green-900 border-green-500 border-[1px] hover:scale-105 duration-150 cursor-pointer ease-in rounded-lg  shadow-lg p-2`}>
                    <div className='border-b border-green-950 w-full p-2'>
                        <h2 className='font-lufga text-xl font-bold'>{company_name}</h2>
                    </div>
                    <div className='flex items-center justify-evenly gap-2 w-full'>
                        <div className='mt-6 flex flex-col gap-4'>

                            <div className='flex flex-col '>
                                <label className='font-lufga text-xs'>No. Of Branches:</label>
                                <p className='text-lg font-lufga'>{data?.branches}</p>
                            </div>
                            <div className='flex flex-col '>
                                <label className='font-lufga text-xs'>Active:</label>
                                <p className='text-lg font-lufga'>{data?.activeBranches}</p>
                            </div>
                            <div className='flex flex-col '>
                                <label className='font-lufga text-xs'>Inactive:</label>
                                <p className='text-lg font-lufga'>{data?.inactiveBranches}</p>
                            </div>
                            <div className='flex flex-col '>
                                    <label className='font-lufga text-xs'>Maximum Branches:</label>
                                    <p className='text-lg font-lufga'>{data?.branches_limit}</p>
                                </div>
                                <div className='flex flex-col '>
                                    <label className='font-lufga text-xs'>Remaining Branch Slots:</label>
                                    <p className='text-lg font-lufga'>{data?.branches_limit - data?.branches}</p>
                                </div>
                            <div className='flex flex-col '>
                                <label className='font-lufga text-xs'>Product:</label>
                                <p className='text-lg font-lufga'>{(product_name)?.split('-')[1]}</p>
                            </div>
                        </div>
                        <div className=' h-60'>
                            <Doughnut
                                data={BranchesChartData}

                            />
                        </div>
                    </div>
                    {/* <CiMenuKebab onClick={(event) => togglePopUp(event, item.id, item)} className='text-neutral-700 cursor-pointer ' /> */}
                </div>
                <div className={`w-full h-full bg-gradient-to-br from-green-100 to-yellow-300  text-yellow-900 border-yellow-500 border-[1px] hover:scale-105 duration-150 cursor-pointer ease-in rounded-lg  shadow-lg p-2`}>
                    <div className='border-b border-green-950 w-full p-2'>
                        <h2 className='font-lufga text-xl font-bold'>Users</h2>
                    </div>
                    <div className='flex items-center justify-evenly gap-2 w-full'>
                        <div className='mt-6 flex flex-col gap-4 '>
                            <div className='flex flex-col '>
                                <label className='font-lufga text-xs'>Total users:</label>
                                <p className='text-lg font-lufga'>{data?.totalUsers}</p>
                            </div>
                            <div className='flex flex-col '>
                                <label className='font-lufga text-xs'>Active users:</label>
                                <p className='text-lg font-lufga'>{data?.totalActive}</p>
                            </div>
                            <div className='flex flex-col '>
                                <label className='font-lufga text-xs'>Inactive users:</label>
                                <p className='text-lg font-lufga'>{data?.totalInactive}</p>
                            </div>
                            <div className='flex flex-col '>
                                <label className='font-lufga text-xs'>Archived users:</label>
                                <p className='text-lg font-lufga'>{data?.totalArchive}</p>
                            </div>
                            <div className='flex flex-col '>
                                    <label className='font-lufga text-xs'>Maximum users:</label>
                                    <p className='text-lg font-lufga'>{data.users_limit}</p>
                                </div>
                                <div className='flex flex-col '>
                                    <label className='font-lufga text-xs'>Remaining user slots:</label>
                                    <p className='text-lg font-lufga'>{data?.users_limit - data?.totalUsers}</p>
                                </div>

                        </div>
                        <div className=' h-60'>
                            <Doughnut
                                data={UsersChartData}

                            />
                        </div>
                    </div>
                </div>

            </div>}
        </div>
 
    );
}

export default RetailOverview;
