import React, { useState } from 'react';
import { MdClose } from "react-icons/md";
import { api_url } from '../../config';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { InfinitySpin } from 'react-loader-spinner';
import api from '../../utils/api';

const AddBranchForm = ({ toggle, fetchBranches }) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [domain, setDomain] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [country, setCountry] = useState('')
    const [town, setTown] = useState('')
    const [city, setCity] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [ghanaPost, setGhanaPost] = useState('')
    const [error, setError] = useState(null)
    const [status, setStatus] = useState('')
    const [loading, setLoading] = useState(false)

    const { token, id, company_name } = useSelector((state) => state.auth)
    const addBranch = async (e) => {
        if (e) {
            e.preventDefault();
        }
        const branchname = `${company_name}-${name}`
        console.log(name);
        
        const branchDetails = {
            name : branchname,
            email,
            domain,
            phone,
            address,
            country,
            town,
            city,
            zip_code: zipCode,
            ghana_post: ghanaPost,
        }
        const required = !name || !email || !address || !country || !city;
        if (required) {
            setError(`Please fill all required field`)
            setTimeout(() => {
                setError('');
            }, 6000);
            return
        }
        try {
            setLoading(true)
            const response = await api.post(api_url + `/api/v1/wholesaler/admin/${id}/branch`, branchDetails, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            setStatus('Branch added successfully')
            fetchBranches()
            setName('')
            setAddress('')
            setPhone('')
            setEmail('')
            setCountry('')
            setCity('')
            setTown('')
            setZipCode('')
            setGhanaPost('')
            setDomain('')
            setTimeout(() => {
                toggle();
            }, 1500);
        } catch (error) {
            setError(error?.response?.data?.error)
          

        } finally {
          
            setLoading(false)
        }
    }


    return (
        <div className='max-w-max min-h-max overflow-auto sm:min-w-[300px] lg:min-w-[600px] mx-auto p-4 '>
            <div className=' mb-6'>
                <p className='font-lufga text-center font-semibold text-neutral-500'>Create New Branch</p>

            </div>
            <form className="grid gap-4 mb-6 md:grid-cols-2">
                {loading && ( // Display loader while loading
                    <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                        <InfinitySpin color="#008DDA" loading={loading} size={36} />
                    </div>
                )}

                <div className='flex flex-col gap-3 w-full overflow-auto'>
                    <label className="text-sm font-lufga flex items-center gap-1" htmlFor="name">Branch Name<span className='font-serif text-red-400'>*</span></label>
                    <div className='flex items-center px-3 py-2 border border-gray-300 rounded-md   focus:border-blue-500'>
                        <span className='min-w-max overflow-auto'>{company_name}-</span>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={name}
                            onChange={(e) => { setName(e.target.value) }}
                            placeholder="Enter branch name"
                            className="focus:outline-none w-full"
                            required
                        />
                    </div>
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1" htmlFor="address">Address<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        id="address"
                        name="address"
                        value={address}
                        onChange={(e) => { setAddress(e.target.value) }}
                        placeholder="123 ABC street"
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1" htmlFor="phone">Phone Number<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        id="phone"
                        name="phone"
                        value={phone}
                        onChange={(e) => { setPhone(e.target.value) }}
                        placeholder="Enter phone number"
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1">Email<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                        placeholder="Enter branch email"
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>

                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1">Country<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        id="country"
                        name="country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        placeholder="e.g Ghana"
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1" >City <span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        value={city}
                        onChange={(e) => { setCity(e.target.value) }}
                        placeholder=""
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga">Town</label>
                    <input
                        type="text"
                        value={town}
                        onChange={(e) => setTown(e.target.value)}
                        placeholder=""
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"

                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga">Zip Code</label>
                    <input
                        type="text"
                        value={zipCode}
                        onChange={(e) => { setZipCode(e.target.value) }}
                        placeholder="Enter zip code"
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"

                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga">Ghana Post</label>
                    <input
                        type="text"
                        value={ghanaPost}
                        onChange={(e) => { setGhanaPost(e.target.value) }}
                        placeholder="e.g GL-038-9022"
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"

                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga" >Website</label>
                    <input
                        type="text"
                        value={domain}
                        onChange={(e) => { setDomain(e.target.value) }}
                        placeholder=""
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"

                    />
                </div>

            </form>
            <div className='flex justify-between items-center'>
                <button onClick={addBranch} className="bg-blue-500 font-lufga text-white py-2 px-4 rounded-md hover:bg-blue-600 shadow-md transition duration-300">Add Branch</button>

                {status ? <div>
                    <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{status}</p>
                </div> : error ? <div>
                    <p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-sm ">
                        {typeof error === 'string' ? error : (
                            Object.keys(error).map((key) => (
                                Array.isArray(error[key]) ? error[key][0] : error[key]
                            ))
                        )}
                    </p>
                </div> : null}
            </div>

        </div>
    );
}

export default AddBranchForm;
