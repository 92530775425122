import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Redux/AuthSlice';
import { userInfoReducer } from './Redux/UserInfoSlice';
import { branchesReducer } from './Redux/BranchSlice';
import { branchDetailsInfoReducer } from './Redux/BranchDetailsSlice';


const store = configureStore({
  reducer: {
    auth: authReducer,
    branchInfo: branchesReducer,
    userInfo:userInfoReducer,
    branchDetailsInfo:branchDetailsInfoReducer,
   

  },
});

export default store;
