import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import wlogo from '../../assets/wlogo.png'
import bg from '../../assets/8043.jpg'
import Profile from '../../components/wholesaleLib/Profile';
import EditProfile from '../../components/wholesaleLib/EditProfile';
import UpdatePassword from '../../components/wholesaleLib/UpdatePassword';
import { InfinitySpin } from 'react-loader-spinner';
import api from '../../utils/api';
import { api_url } from '../../config';

const ProfilePage = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [isChangingPassword, setIsChangingPassword] = useState(false);
    const { id, token } = useSelector((state) => state.auth);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    useEffect(() => {
        fetchProfile();
    }, [id]);

    const fetchProfile = async () => {
        try {
            setLoading(true);
            const response = await api.get(api_url + `/api/v1/wholesaler/admin/${id}/profile`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            setData(response.data.data);
        } catch (error) {
            setError(error.message);
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    

    if (isLoading) {
        
        return <div className="flex justify-center items-center h-screen text-red-500"> <InfinitySpin color="#008DDA" size={40} /></div>;

    }

    if (error) {
        return <div className="flex justify-center items-center h-screen text-red-500">{error}</div>;
    }


    return (
     
        <div>
            {isEditing ? (
                <EditProfile
                    onCancel={() => setIsEditing(false)}
                    onSave={(updatedProfile) => {
                        setIsEditing(false);
                        setData(updatedProfile)
                        

                    }}
                    data={data}
                />
            ) : isChangingPassword ? (
                <UpdatePassword
                    onCancel={() => setIsChangingPassword(false)}
                    onSave={(updatedProfile) => {
                        // Handle saving the updated password here
                        setIsChangingPassword(false);
                    }}
                />
            ) : (
                <Profile
                    onEdit={() => setIsEditing(true)}
                    onChangePassword={() => setIsChangingPassword(true)}
                    data={data}
                />
            )}
        </div>


    );
}

export default ProfilePage;
