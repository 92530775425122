import React, { useEffect } from "react";
import LoginPage from "./pages/loginPage";
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SignupPage from "./pages/signupPage";
import Layout from "./pages/wholesale/WholesaleLayout";
import BranchesPage from "./pages/wholesale/branchesPage";
import WholesaleArchivePage from "./pages/wholesale/WholesaleArchivePage";
import { useSelector } from "react-redux";
import ForgotPasswordPage from "./pages/forgotPasswordPage";
import ForgotRetailPasswordPage from "./pages/retail/forgotRetailPasswordPage";
import ResetPasswordPage from "./pages/reset-passwordPage";
import PrivateRoute from "./utils/PrivateRoute";
import ResetRetailPassword from "./components/retailLib/resetRetailPassword";
import ConfirmEmailVerification from "./components/wholesaleLib/confirmEmailVerification";
import RetailLayout from "./pages/retail/RetailLayout";
import RetailAdminDashboard from "./pages/retail/RetailAdminDashboard";
import RetailBranchesPage from "./pages/retail/RetailBranchesPage";
import RetailArchivePage from "./pages/retail/RetailArchivePage";
import BranchDetails from "./components/wholesaleLib/BranchDetails";
import ProfilePage from "./pages/wholesale/ProfilePage";
import RetailBranchDetails from "./components/retailLib/RetailBranchDetails";
import RetailProfilePage from "./pages/retail/RetailProfilePage";
import AdminDashboardPage from "./pages/wholesale/AdminDashboardPage";
import ConfirmRetailEmailVerification from "./components/retailLib/confirmRetailEmailVerification";
import LoginForm from "./components/internalAdminLib/LoginForm";
import SignUpForm from "./components/internalAdminLib/SignUpForm";
import ForgotPassword from "./components/internalAdminLib/ForgotPassword";
import { type } from "@testing-library/user-event/dist/type";
import TokenSection from "./components/internalAdminLib/TokenSection";

function App() {

  const auth = useSelector((state) => state.auth)
  const navigate = useNavigate()

  const token = sessionStorage.getItem('token')
  // useEffect(() => {
  //   if (!token) {
  //     navigate('/')
  //   }
  // }, [token])



  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/forgot-wholesale-pass" element={<ForgotPasswordPage />} />
      <Route path="/forgot-retail-pass" element={<ForgotRetailPasswordPage />} />
      <Route path="/reset-wholesale-password/:token" element={<ResetPasswordPage />} />
      <Route path="/reset-retail-password/:token" element={<ResetRetailPassword />} />
      <Route path="/retail-verify-email/:id/:hash" element={<ConfirmRetailEmailVerification />} />
      <Route path="/wholesale-verify-email/:id/:hash" element={<ConfirmEmailVerification />} />
      <Route path="/internal-admin-login" element={<LoginForm />} />
      <Route path="/internal-admin-signup" element={<SignUpForm />} />
      <Route path="/internal-admin-forgot-password" element={<ForgotPassword />} />


      <Route element={<PrivateRoute type="pharmtrix-wholesale" />}>
        <Route path="/wholesale/*" element={<Layout />}>
          <Route path="dashboard" element={<AdminDashboardPage />} />
          <Route path="branches" element={<BranchesPage />} />
          <Route path="branches/branch-info" element={<BranchDetails />} />
          {/* <Route path="archive" element={<WholesaleArchivePage />} /> */}
          <Route path="profile" element={<ProfilePage />} />
        </Route>
      </Route>

      <Route element={<PrivateRoute type="pharmtrix-retail" />}>
        <Route path="/retail/*" element={<RetailLayout />}>
          <Route path="dashboard" element={<RetailAdminDashboard />} />
          <Route path="branches" element={<RetailBranchesPage />} />
          {/* <Route path="archive" element={<RetailArchivePage />} /> */}
          <Route path="branches/branch-info" element={<RetailBranchDetails />} />
          <Route path="profile" element={<RetailProfilePage />} />

        </Route>
      </Route>
      <Route element={<PrivateRoute type="admin" />}>
        <Route path="generate-token" element={<TokenSection/>}/>
      </Route>
      
    </Routes>
  );
}



export default App;
