import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { api_url } from "../config";
import { selectBranchData } from "./BranchSlice";




export const checkData = createAsyncThunk(
    'checkdata',
    async (_, { getState }) => {
      const BranchData = selectBranchData(getState()); // Accessing branch data from the Redux store
      console.log("Branch Data:", BranchData);
    }
  );



export const fetchUserData=createAsyncThunk("fetchUserData", 
async (_,{getState})=>{
    try {
        const branchData = selectBranchData(getState())
        const response = await axios.get(api_url + `/api/wholesaler/admin/${branchData.admin_id}/branch/${branchData.id}/user`);
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch data');
    }
}

)

const userInfoSlice = createSlice({
    name:'userInfo',
    initialState:{
        data:null,
        isloading:false,
        error: false,
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchUserData.pending, (state) => {
            state.isloading = true;
            state.error = null;
          })
          .addCase(fetchUserData.fulfilled, (state, action) => {
            state.isloading = false;
            state.data = action.payload;
          })
          .addCase(fetchUserData.rejected, (state, action) => {
            state.isloading = false;
            state.error = action.error.message;
          });
      }

})

export const userInfoReducer = userInfoSlice.reducer;