import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { useParams } from 'react-router';
import { api_url } from '../../config';
import { Link } from 'react-router-dom';

const ConfirmEmailVerification = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [verificationStatus, setVerificationStatus] = useState('');
    const { id, hash } = useParams();
 
    

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                
                const response = await axios.get(api_url+`/api/wholesaler/admin/verify-email/${id}/${hash}`);

                if (response.status === 200) {
                    setVerificationStatus('success');
                } else {
                    setVerificationStatus('error');
                }
                console.log(response.data);
                
            } catch (error) {
                console.error('Error verifying email:', error);
                setVerificationStatus('error');
                
            } finally {
                setIsLoading(false);
            }
        };

        verifyEmail();
    }, [id, hash]);
    return (
        <div className='fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-200 bg-opacity-75 z-50'>
            <div className='flex flex-col text-center gap-4'>
            <div>
              { isLoading && <Oval
                    visible={true}
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />}
                 {!isLoading && verificationStatus === 'success' && <p>Email verified successfully!</p>}
                {!isLoading && verificationStatus === 'error' && <p>Failed to verify email. Please try again later.</p>}
            </div>
                <Link to='/'>
                <p className='hover:underline text-blue-900'>Go to Login</p>
                </Link>
            </div>
        
        </div>
    );
}

export default ConfirmEmailVerification;
