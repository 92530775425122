import React from 'react';
import ForgotPassword from '../components/wholesaleLib/forgotPassword';

const ForgotPasswordPage = () => {
    return (
        <div className=' flex justify-center items-center bg-gray-50 h-screen w-full '>
            <ForgotPassword/>
        </div>
    );
}

export default ForgotPasswordPage;
