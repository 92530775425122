import React, { useEffect, useState } from 'react';
import { IoEyeOutline } from 'react-icons/io5';
import { FaRegEyeSlash } from 'react-icons/fa';
import { InfinitySpin } from 'react-loader-spinner';
import { Link, useSearchParams,useParams } from 'react-router-dom';
import axios from 'axios';
import { api_url } from '../../config';

const ResetPassword = () => {

    const [error, setError] = useState('')
    const [loading, setLoading] = useState('')
    const [selectedOption, setSelectedOption] = useState(null);
    const [status, setStatus] = useState('')
    const [passwordState, setPasswordState] = useState(false);
    const [confirm_passwordState, setConfirm_passwordState] = useState(false)
    const [password, setPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');
    const [email,setEmail] = useState('')
    const [searchParams] = useSearchParams()
    const {token} = useParams()

    useEffect(()=>{
        setEmail(searchParams.get("email"))
    },[])

    const handlePasswordToggle = (e) => {
        e.preventDefault();
        setPasswordState(!passwordState);
    };
    const handleConfirmPasswordToggle = (e) => {
        e.preventDefault();
        setConfirm_passwordState(!confirm_passwordState)
    }
    
    const handleSubmit =async(e)=>{
        e.preventDefault()
        try {
            setLoading(true);
            const response = await axios.post(api_url+'/api/wholesaler/admin/reset-password', {
                email,
                token,
                password,
                password_confirmation
            });
            if (response.status == 200) {
                setStatus('Password reset successful')
                setTimeout(() => {
                    setStatus('');
                }, 10000);

            }
        } catch (error) {
            console.log(error);
         setError(error?.response?.data?.error)
         setTimeout(() => {
            setError('');
        }, 3000);
        } finally {
            setPassword('')
            setPassword_confirmation('')
            setLoading(false);
        }
    }
    return (
       
            <div className=' m-2 shadow px-6 py-4 rounded-md w-full sm:w-[50vw] xl:w-[30vw]'>
                <form className='space-y-4 md:space-y-6' >
                    <div>
                        <p className='text-xl font-lufga font-medium text-neutral-600 text-center mb-4'>Reset Password</p>
                        <p className='text-sm font-lufga  text-neutral-600 text-center'>Wholesale admin</p>
                    </div>
                    <div>
                        <label
                            htmlFor='password'
                            className='block mb-2 text-sm font-medium text-gray-900 '
                        >
                            New Password
                        </label>
                        <div className='flex justify-between items-center bg-gray-50 border text-xs border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600  w-full p-2.5'>
                            <input
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type={passwordState ? 'text' : 'password'}
                                name='password'
                                placeholder='••••••••'
                                className='bg-gray-50 focus:outline-none w-full'
                                required
                            />
                            {passwordState ? (
                                <IoEyeOutline size={20} onClick={handlePasswordToggle} />
                            ) : (
                                <FaRegEyeSlash size={20} onClick={handlePasswordToggle} />
                            )}
                        </div>
                    </div>
                    <div>
                        <label
                            className='block mb-2 font-lufga text-sm font-medium text-gray-900 '
                        >
                            Confirm New Password
                        </label>
                        <div className='flex justify-between items-center bg-gray-50 border text-xs border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600  w-full p-2.5'>
                            <input
                                value={password_confirmation}
                                onChange={(e) => setPassword_confirmation(e.target.value)}
                                type={confirm_passwordState ? 'text' : 'password'}
                                name='confirm_password'
                                placeholder='••••••••'
                                className='bg-gray-50 focus:outline-none w-full'
                                required
                            />
                            {confirm_passwordState ? (
                                <IoEyeOutline size={20} onClick={handleConfirmPasswordToggle} />
                            ) : (
                                <FaRegEyeSlash size={20} onClick={handleConfirmPasswordToggle} />
                            )}
                        </div>
                    </div>
                    {status ? <div>
                        <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{status}</p>
                    </div> : error ? <div>
                        <p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-sm ">
                            {typeof error === 'string' ? error : (
                                Object.keys(error).map((key) => (
                                    Array.isArray(error[key]) ? error[key][0] : error[key]
                                ))
                            )}
                        </p>
                    </div> : null}


                    <button
                       
                        onClick={handleSubmit}
                        className='w-full mt-8 text-white font-lufga bg-neutral-500 hover:bg-neutral-600 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center '
                    >

                        Reset Password
                    </button>
                    {loading ? (<div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                        <InfinitySpin color="#387ADF" loading={loading} size={32} />
                    </div>) : null}
                    <div className='flex justify-between'>
                        <Link to='/'>
                            <p className='hover:underline cursor-pointer font-lufga text-sm text-blue-800'>Go to login</p>
                        </Link>

                    </div>
                </form>
            </div>
      
    );
}

export default ResetPassword;
