import { createSlice } from '@reduxjs/toolkit';

// const branchDetailsSlice = createSlice({
//     name: 'branchDetailsInfo',
//     initialState: {
//         id:  null,
//         email: null,
//         admin_id: sessionStorage.getItem('admin_id') || null,
//         uuid:  null,
//         name: null,
//         address: sessionStorage.getItem('address') || null,
//         country: sessionStorage.getItem('country') || null,
//         city: sessionStorage.getItem('city') || null,
//         updated_at: null,
//         created_at: null,
//         phone:sessionStorage.getItem('phone') || null,
//         domain:sessionStorage.getItem('domain') || null,
//         status:sessionStorage.getItem('status') || null,
//         town:sessionStorage.getItem('town') || null,
//         zip_code: sessionStorage.getItem('zip_code') || null,
//         ghana_post: sessionStorage.getItem('ghana_post') || null,
       
     
//     },
//     reducers: {
//         setBranchData: (state, action) => {
//             const { id, uuid, name, email, address, created_at, updated_at, country, city, admin_id,zip_code,ghana_post,phone,town,domain,status } = action.payload;
//             state.id = id;
//             state.uuid = uuid;
//             state.name = name;
//             state.email = email;
//             state.address= address;
//             state.created_at = created_at;
//             state.updated_at = updated_at;
//             state.country = country;
//             state.city = city;
//             state.status= status;
//             state.domain= domain;
//             state.zip_code= zip_code;
//             state.ghana_post= ghana_post;
//             state.admin_id= admin_id;
//             state.phone= phone;
//             state.town= town;

//             // Update sessionStorage...here adey use for the login
          
//             // sessionStorage.setItem('id', id);
//             // sessionStorage.setItem('uuid', uuid);
//             // sessionStorage.setItem('name', name);
//             // sessionStorage.setItem('email', email);
//             // sessionStorage.setItem('created_at', created_at);
//             // sessionStorage.setItem('updated_at', updated_at);
//         },
//         clearAuthData: (state) => {
         
//         }
//     },
// });


const branchDetailsSlice = createSlice({
    name: 'branchDetailsInfo',
    initialState: [],
    reducers: {
        setBranchData: (state, action) => {
            return action.payload; // Assume payload is an array of branch data
        },
        addBranchData: (state, action) => {
            state.push(action.payload); // Add single branch data to the state
        },
        clearBranchData: (state) => {
            return []; // Clear branch data
        }
    },
});

export const { setBranchData } = branchDetailsSlice.actions;
export const branchDetailsInfoReducer =  branchDetailsSlice.reducer;
