import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        id: sessionStorage.getItem('id') || null,
        uuid: sessionStorage.getItem('uuid') || null,
        name: sessionStorage.getItem('name') || null,
        email: sessionStorage.getItem('email') || null,
        email_verified_at:sessionStorage.getItem('email_verified_at') ,
        created_at:sessionStorage.getItem('created_at') || null,
        updated_at:sessionStorage.getItem('updated_at') || null,
        company_name:sessionStorage.getItem('company_name') || null,
        product_name:sessionStorage.getItem('product_name') || null,
        phone:sessionStorage.getItem('phone') || null,
        token: sessionStorage.getItem('token') || null,
        token_type: 'Bearer',
        loading: false
    },
    reducers: {
        setAuthData: (state, action) => {
            const { id, uuid, name,phone, email, email_verified_at, created_at, updated_at, token, company_name, product_name } = action.payload;
            state.id = id;
            state.uuid = uuid;
            state.name = name;
            state.email = email;
            state.phone = phone;
            state.email_verified_at = email_verified_at;
            state.created_at = created_at;
            state.updated_at = updated_at;
            state.company_name = company_name;
            state.product_name = product_name;
            state.token = token;

            // Update sessionStorage...here adey use for the login
            sessionStorage.setItem('token', token);
            sessionStorage.setItem('company_name', company_name);
            sessionStorage.setItem('product_name', product_name);
            sessionStorage.setItem('id', id);
            sessionStorage.setItem('uuid', uuid);
            sessionStorage.setItem('name', name);
            sessionStorage.setItem('email', email);
            sessionStorage.setItem('phone', phone);
            sessionStorage.setItem('created_at', created_at);
            sessionStorage.setItem('updated_at', updated_at);
            sessionStorage.setItem('email_verified_at', email_verified_at);
        },
        clearAuthData: (state) => {
            state.id = null;
            state.uuid = null;
            state.name = null;
            state.email = null;
            state.email_verified_at = null;
            state.created_at = null;
            state.updated_at = null;
            state.token = null;
            state.company_name = null;
            state.phone = null;
            state.product_name = null;
            state.loading= false

            // Clear sessionStorage...usually I dey use for the logout
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('company_name');
            sessionStorage.removeItem('product_name');
            sessionStorage.removeItem('id');
            sessionStorage.removeItem('uuid');
            sessionStorage.removeItem('name');
            sessionStorage.removeItem('email');
            sessionStorage.removeItem('created_at');
            sessionStorage.removeItem('updated_at');
            sessionStorage.removeItem('phone');
            sessionStorage.removeItem('email_verified_at');
        },
        logoutState:(state,action)=>{
            state.loading = action.payload
        }
    },
});

export const selectAuthData = (state) => state.auth;
export const { setAuthData, clearAuthData,logoutState } = authSlice.actions;
export default authSlice.reducer;
