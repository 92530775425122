import React from 'react';
import SignupForm from '../components/signupForm';

const SignupPage = () => {
    return (
        <div className='' >
            <SignupForm/>
        </div>
    );
}

export default SignupPage;
