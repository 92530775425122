import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { api_url } from "../../config";
import { useSelector } from "react-redux";
import axios from "axios";
import { InfinitySpin } from "react-loader-spinner";
import { Switch } from "antd";
import api from "../../utils/api";

const EditUser = ({ toggle, fetchUsers, user, item, page }) => {
    // const [name, setName] = useState(user?.name)
    const [firstname, setFirstname] = useState(user?.first_name);
    const [lastname, setLastname] = useState(user?.last_name);
    const [username, setUsername] = useState(user?.username);
    const [email, setEmail] = useState(user?.email);
    const [title, setTitle] = useState(user?.title );
    const [position, setPosition] = useState(user?.position);
    const [permissions, setPermissions] = useState("");
    const [password, setPassword] = useState("");
    const [confirm_password, setConfirm_password] = useState("");
    const [phone, setPhone] = useState(user?.phone);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState("");
    const [userStatus, setUserStatus] = useState(user?.status);
    const [loading, setLoading] = useState(false);
    const [selectedPermissions, setSelectedPermissions] = useState(
        user?.roles || []
    );

    // const[users,setUsers] = useState([])
    const { token } = useSelector((state) => state.auth);
    useEffect(() => {
        setSelectedPermissions(user?.roles || []);
    }, [user?.roles]);
    const permissionCategories = [
        {
            label: "User Management",
            permissions: [
                { value: "userViewer", label: "View Users" },
                { value: "userWriter", label: "Manage Users" },
            ],
        },
        {
            label: "Supplier Management",
            permissions: [
                { value: "supplierViewer", label: "View Suppliers" },
                { value: "supplierWriter", label: "Manage Suppliers" },
                { value: "supplierAdmin", label: "Administer Suppliers" },
            ],
        },
        {
            label: "Retailer Management",
            permissions: [
                { value: "retailerViewer", label: "View Retailers" },
                { value: "retailerWriter", label: "Manage Retailers" },
                { value: "retailerAdmin", label: "Administer Retailers" },
            ],
        },
        {
            label: "Order Management",
            permissions: [
                { value: "orderViewer", label: "View Orders" },
                { value: "orderWriter", label: "Manage Orders" },
                // { value: "orderAdmin", label: "Administer Orders" },
            ],
        },
        {
            label: "Inventory Management",
            permissions: [
                { value: "inventoryViewer", label: "View Inventories" },
                { value: "inventoryWriter", label: "Manage Inventories" },
                { value: "inventoryAdmin", label: "Administer Inventories" },
            ],
        },
        {
            label: "Stock Management",
            permissions: [
                { value: "stockViewer", label: "View Stocks" },
                { value: "stockWriter", label: "Manage Stocks" },
                { value: "stockAdmin", label: "Administer Stocks" },
            ],
        },
        {
            label: "Transaction Management",
            permissions: [
                { value: "transactionViewer", label: "View Transactions" },
                { value: "transactionWriter", label: "Manage Transactions" },
                
            ],
        },
        {
            label: "Other Permissions",
            permissions: [
                { value: "executiveViewer", label: "Executive View" },
            ],
        },
    ];
    const handlePermissionChange = (category, permission) => {
        setSelectedPermissions(prevPermissions => {
            if (prevPermissions.includes(permission)) {
                // If the permission is already selected, remove it
                return prevPermissions.filter(p => p !== permission);
            } else {
                // If the permission is not selected, add it and remove any other permissions from the same category
                const otherCategoryPermissions = prevPermissions.filter(p =>
                    !category.permissions.some(catPerm => catPerm.value === p)
                );
                return [...otherCategoryPermissions, permission];
            }
        });
    };
    
    console.log(selectedPermissions);
    const editUser = async (e) => {
        if (e) {
            e.preventDefault();
        }

        const userDetails = {
            first_name: firstname,
            last_name: lastname,
            username,
            email,
            phone,
            title,
            position,
            roles: selectedPermissions,
            status: userStatus,
        };
        const required = !email || !firstname||!lastname ||! username || !phone ||!selectedPermissions || !userStatus || !position;
        if (required) {
            setError(`Please fill all required field`);
            setTimeout(() => {
                setError("");
            }, 6000);
            return;
        }
        try {
            setLoading(true);
            const response = await api.put(
                api_url +
                `/api/v1/wholesaler/admin/${item.admin_id}/branch/${item.id}/user/${user.id}`,
                userDetails,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setStatus("User updated successfully");
            fetchUsers();
            // setName('')
            // setPhone('')
            // setEmail('')
            // setTitle('')
            // setPassword('')
            // setConfirm_password('')
            setTimeout(() => {
                toggle();
            }, 1500);
        } catch (error) {
            setError(error?.response?.data?.error);
            setTimeout(() => {
                setError("");
            }, 6000);
        } finally {
            setLoading(false);
        }
    };
    const handleUserStatus = (checked) => {
        const status = checked ? "active" : "inactive";
        setUserStatus(status);
    };

    const checker = user?.status === "active";

    return (
        <div className='max-w-[90vw] min-h-max  overflow-auto mx-auto p-4'>
            <div className=' mb-6 flex justify-between  items-center '>
                <p className='font-lufga text-center font-semibold text-neutral-500'>Edit User</p>
                <div className='flex items-center gap-3 '>
                    <label className="text-sm font-lufga" >Status</label>
                    <Switch
                        defaultChecked={checker}
                        checkedChildren="active"
                        unCheckedChildren="inactive"
                        onChange={handleUserStatus}



                    />
                </div>
            </div>
            <form className="grid gap-4 mb-6 md:grid-cols-2 text-xs">
                {loading && ( // Display loader while loading
                    <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                        <InfinitySpin color="#008DDA" loading={loading} size={36} />
                    </div>
                )}

                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1" htmlFor="name">Firstname<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        value={firstname}
                        onChange={(e) => { setFirstname(e.target.value) }}
                        placeholder="Enter user's first name"
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1" htmlFor="name">Lastname<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        value={lastname}
                        onChange={(e) => { setLastname(e.target.value) }}
                        placeholder="Enter user's last name"
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1" >Username<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={username}
                        onChange={(e) => { setUsername(e.target.value) }}
                        placeholder="User's username"
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1">Email<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                        placeholder="Enter user's email"
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>

                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1" >Title</label>
                    <select
                        value={title}
                        onChange={(e) => { setTitle(e.target.value) }}
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    >
                        <option value="" >Select User's Title</option>
                        <option value="PharmD">PharmD (Doctor of Pharmacy)</option>
                        <option value="Clinical Pharmacist">Clinical Pharmacist</option>
                        <option value="MD">MD (Doctor of Medicine)</option>
                        <option value="DDS">DDS (Doctor of Dental Surgery)</option>
                        <option value="DO">DO (Doctor of Osteopathic Medicine)</option>
                        <option value="RPh">RPh (Registered Pharmacist)</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1" >Position<span className='font-serif text-red-400'>*</span></label>
                    <select
                        value={position}
                        onChange={(e) => { setPosition(e.target.value) }}
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    >
                        <option value="" >Select User's position</option>
                        <option value="Pharmacist">Pharmacist</option>
                        <option value="Pharmacy Technician">Pharmacy Technician</option>
                        <option value="Pharmacy Assistant">Pharmacy Assistant</option>
                        <option value="Pharmacy Manager">Pharmacy Manager</option>
                        <option value="Pharmacy Intern">Pharmacy Intern</option>
                        <option value="Pharmacy Clerk">Pharmacy Clerk</option>
                        <option value="Pharmacy Consultant">Pharmacy Consultant</option>
                        <option value="Compounding Technician">Compounding Technician</option>
                        <option value="Pharmacy Cashier">Pharmacy Cashier</option>

                    </select>
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1" htmlFor="phone">Phone Number<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        id="phone"
                        name="phone"
                        value={phone}
                        onChange={(e) => { setPhone(e.target.value) }}
                        placeholder="Enter phone number"
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>

            </form>

            <div className='col-span-2 tetx-xs'>
                <label className="text-sm font-lufga flex items-center gap-1">Permissions<span className='font-serif text-red-400'>*</span></label>
                <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4'>
                {permissionCategories.map((category) => (
                        <div key={category.label} className='flex flex-col items-start'>
                            <div>
                                <p className='font-lufga'>{category.label}</p>
                            </div>
                            <div className='flex flex-col gap-2'>
                                {category.permissions.map((permission) => (
                                    <div key={permission.value} className='flex items-center gap-1'>
                                        <input
                                            type="checkbox"
                                            id={permission.value}
                                            name={category.label}
                                            value={permission.value}
                                            checked={selectedPermissions.includes(permission.value)}
                                            onChange={() => handlePermissionChange(category, permission.value)}
                                            className="form-checkbox text-green-500"
                                        />
                                        <label htmlFor={permission.value} className='text-xs'>{permission.label}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='flex justify-between items-center'>
                <div>
                    {status ? <div>
                        <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{status}</p>
                    </div> : error ? <div>
                        <p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-sm ">
                            {typeof error === 'string' ? error : (
                                Object.keys(error).map((key) => (
                                    Array.isArray(error[key]) ? error[key][0] : error[key]
                                ))
                            )}
                        </p>
                    </div> : null}</div>

                <button onClick={editUser} className="bg-blue-500 font-lufga text-white py-2 px-4 rounded-md hover:bg-blue-600 shadow-md transition duration-300">Update</button>

            </div>

        </div>
    );
};

export default EditUser;
