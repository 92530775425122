import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HiOutlineViewGrid } from 'react-icons/hi'
import { LuGitBranchPlus } from "react-icons/lu";
import { FaRecycle } from "react-icons/fa";
import { IoArchiveOutline } from "react-icons/io5";
import { FcNext } from "react-icons/fc";

const Sidebar = () => {
    const location = useLocation()
    const getActiveMenuItem = (path) => {
        if (path.includes('/wholesale/dashboard')) return 'General Overview';
        if (path.includes('/wholesale/branches')) return 'Manage Branches';
    
        return '';
    };

    const activeMenuItem = getActiveMenuItem(location.pathname);

    return (
        <div>
            <div className='overflow-y-auto'>
                <div>
                    <p className='text-[#CAF4FF] text-xl my-4 cursor-pointer'>Admin Dashboard</p>
                </div>
                <div className='flex flex-col gap-2'>

                    <Link to='dashboard'>
                        <div
                            className={`flex items-center rounded-md gap-2 p-2 hover:opacity-75 ${activeMenuItem === 'General Overview' ? 'bg-blue-900' : ''}`}
                        >
                            <HiOutlineViewGrid size={20} className='text-white' />

                            <button className={`text-white ${activeMenuItem === 'General Overview' ? 'text-[#CAF4FF]' : ''}`}>General Overview</button>

                        </div>
                    </Link>

                    <Link to='branches'>
                        <div
                            className={`flex items-center rounded-md gap-2 p-2 hover:opacity-75 ${activeMenuItem === 'Manage Branches' ? 'bg-blue-900' : ''}`}
                        >
                            <LuGitBranchPlus size={20} className='text-white' />

                            <button className={`text-white ${activeMenuItem === 'Manage Branches' ? 'text-[#CAF4FF]' : ''}`}>Manage Branches</button>

                        </div>
                    </Link>
{/* 
                    <Link to='archive'>
                        <div
                            className={`flex items-center gap-2 p-2 hover:bg-neutral-600 ${activeMenuItem === 'Recycle Bin' ? 'bg-neutral-600' : ''}`}
                            onClick={() => handleMenuItemClick('Recycle Bin')}
                        >
                            <IoArchiveOutline size={20} className='text-neutral-400' />
                                <button className={`text-neutral-400 ${activeMenuItem === 'Recycle Bin' ? 'text-white' : ''}`}>Archive</button>
                        </div>
                    </Link> */}

                </div>
            </div>
        </div>

    );
}

export default Sidebar;
