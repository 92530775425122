import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/wholesaleLib/sidebar';
import Header from '../../components/wholesaleLib/header';
import { Outlet } from 'react-router';
import { useSelector } from 'react-redux';
import { InfinitySpin } from 'react-loader-spinner';
import VerifyEmailModal from '../../components/verifyEmailModal';
import RetailHeader from '../../components/retailLib/RetailHeader';
import RetailSidebar from '../../components/retailLib/RetailSidebar';
import { FcNext, FcPrevious } from "react-icons/fc";

const RetailLayout = () => {
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [isLargeScreen, setIsLargeScreen] = useState(false);
    const { loading, email_verified_at } = useSelector((state) => state.auth);

    useEffect(() => {
        const handleResize = () => {
            const largeScreen = window.innerWidth >= 1024; // sm breakpoint
            setIsLargeScreen(largeScreen);
            setIsSidebarVisible(largeScreen);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    return (
        <div className="flex flex-col h-screen">
        <RetailHeader />
        <div className="flex flex-grow overflow-hidden relative">
            <div className={`
            absolute top-0 left-0 h-full
            bg-[#091d16] p-4 font-lufga
            transition-all duration-300 ease-in-out
            ${isLargeScreen
                    ? (isSidebarVisible ? 'w-[250px]' : 'w-0')
                    : 'w-[250px]'
                }
            ${isSidebarVisible ? 'translate-x-0' : '-translate-x-full'}
            ${isLargeScreen ? '' : 'z-30'}
        `}>
                <RetailSidebar />
            </div>
            <div className={`
            flex-grow overflow-auto transition-all duration-300 ease-in-out
            ${isLargeScreen && isSidebarVisible ? 'ml-[250px]' : 'ml-0'}
        `}>
                <div className="flex-grow overflow-auto px-2">
                    <Outlet />
                </div>
            </div>
            <button
                onClick={toggleSidebar}
                className="fixed top-10 left-0 z-40 bg-green-900 bg-opacity-10 backdrop-blur-xl 
                       border border-white border-opacity-20 p-2 hover:bg-opacity-20 rounded-lg"
            >
                {isSidebarVisible ? <FcPrevious size={20} /> : <FcNext size={20} />}
            </button>
                {loading && (
                    <div className="absolute inset-0 flex justify-center items-center bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm z-50">
                        <div className='flex flex-col items-center gap-4'>
                            <InfinitySpin color="#059212" loader={loading} size={32} />
                            <p className='text-center'>Logging Out....</p>
                        </div>
                    </div>
                )}
            </div>
            {(!email_verified_at || email_verified_at === 'null') && <VerifyEmailModal />}
        </div>
    );
}

export default RetailLayout;
