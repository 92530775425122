import React, { useState } from 'react';
import { IoEyeOutline } from 'react-icons/io5';
import { FaRegEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthData } from '../Redux/AuthSlice';
import { InfinitySpin } from 'react-loader-spinner';
import { api_url } from '../config';
import rlogo from '../assets/rlogo.png';
import wlogo from '../assets/wlogo.png';


const LoginForm = () => {
    const [activeTab, setActiveTab] = useState('wholesale');
    const switchTab = (tab) => {
        setActiveTab(tab);
    };
    const navigate = useNavigate();
    const [wholesale_email, setWholesale_email] = useState('');
    const [wholesale_password, setWholesale_password] = useState('');
    const [retail_email, setRetail_email] = useState('');
    const [retail_password, setRetail_password] = useState('');
    const [passwordState, setPasswordState] = useState(false);
    const [wholesaleField, setWholesaleField] = useState('');
    const [retailField, setRetailField] = useState('');
    const [loading, setLoading] = useState(false);
    const [wholesaleError, setWholesaleError] = useState(null)
    const [retailError, setRetailError] = useState(null)

    const handlePasswordToggle = (e) => {
        e.preventDefault();
        setPasswordState(!passwordState);
    };
    const dispatch = useDispatch()

    const handleWholesaleLogin = async (e) => {
        e.preventDefault();
        const loginForm = {
            email: wholesale_email,
            password: wholesale_password
        }
        try {
            setLoading(true);
            const response = await axios.post(api_url + '/api/wholesaler/admin/login', loginForm, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            // console.log(response.data);
            dispatch(setAuthData(response.data))
            navigate('/wholesale/dashboard')

        } catch (error) {
            setWholesaleError(error?.response?.data?.error)
            //console.log(error?.response?.data?.error);
        } finally {
            setLoading(false);
        }
    }

    const handleRetailLogin = async (e) => {
        e.preventDefault();
        const loginForm = {
            email: retail_email,
            password: retail_password
        }
        try {
            setLoading(true);
            const response = await axios.post(api_url + '/api/retailer/admin/login', loginForm, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            // console.log(response.data);
            dispatch(setAuthData(response.data))
            navigate('/retail/dashboard')
        } catch (error) {
            setRetailError(error?.response?.data?.error)
        } finally {
            setLoading(false);
        }
    }
    const auth = useSelector((state) => state.auth)


    return (
        <div className='mt-[-5em]'>
            <section className=''>
                {/* <p className='text-3xl my-4 text-center'>PHARMTRIX ADMIN</p> */}
            {activeTab == 'wholesale' ? <div className='flex items-center justify-center m-4'>
                <img src={wlogo} className='w-[100px] md:w-[120px]  rounded-md shadow-lg' />
            </div> : <div className='flex items-center justify-center m-4'>
                <img src={rlogo} className='w-[100px] md:w-[120px]  rounded-md shadow-lg' />
            </div>}

                <div className=''>
                    <div className='w-[100vw] sm:w-[55vw] xl:w-[35vw]  sm:rounded-lg sm:shadow'>
                        <div className='flex font-lufga gap-4 mx-4'>
                            <button
                                className={`text-xs sm:text-sm cursor-pointer py-2 px-4 ${activeTab === 'wholesale'
                                    ? 'border-b-2 border-blue-500 text-blue-500'
                                    : 'text-gray-500'
                                    }`}
                                onClick={() => switchTab('wholesale')}
                            >
                                Wholesale Admin
                            </button>
                            <button
                                className={`text-xs sm:text-sm cursor-pointer py-2 px-4 ${activeTab === 'retail'
                                    ? 'border-b-2 border-green-500 text-green-500'
                                    : 'text-gray-500'
                                    }`}
                                onClick={() => switchTab('retail')}
                            >
                                Retail Admin
                            </button>

                        </div>
                        {activeTab === 'wholesale' ? (
                            <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
                                <form className='space-y-4 md:space-y-6' >
                                    {wholesaleError && (
                                        <div>
                                            <p className="bg-red-300 font-lufga rounded-md shadow-md px-2 py-1 text-sm ">
                                                {typeof wholesaleError === 'string' ? wholesaleError : (
                                                    Object.keys(wholesaleError).map((key) => (
                                                        Array.isArray(wholesaleError[key]) ? wholesaleError[key][0] : wholesaleError[key]
                                                    ))
                                                )}
                                            </p>
                                        </div>
                                    )}
                                    <div>
                                        <label
                                            htmlFor='email'
                                            className='block font-lufga mb-2 text-sm font-medium text-gray-900 '
                                        >
                                            Email
                                        </label>
                                        <input
                                            value={wholesale_email}
                                            onChange={(e) => setWholesale_email(e.target.value)}
                                            type='email'
                                            name='email'
                                            id='email'
                                            className='bg-gray-50 border border-gray-300 text-gray-900 text-xs sm:text-sm rounded-lg focus:outline-none  block w-full p-2.5 '
                                            placeholder='wholesaleadmin@company.com'
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label
                                            htmlFor='password'
                                            className='block mb-2 font-lufga text-sm font-medium text-gray-900 '
                                        >
                                            Password
                                        </label>
                                        <div className='flex justify-between items-center bg-gray-50 border text-xs border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600  w-full p-2.5'>
                                            <input
                                                value={wholesale_password}
                                                onChange={(e) => setWholesale_password(e.target.value)}
                                                type={passwordState ? 'text' : 'password'}
                                                name='password'
                                                placeholder='••••••••'
                                                className='bg-gray-50 focus:outline-none w-full'
                                                required
                                            />
                                            {passwordState ? (
                                                <IoEyeOutline size={20} onClick={handlePasswordToggle} />
                                            ) : (
                                                <FaRegEyeSlash size={20} onClick={handlePasswordToggle} />
                                            )}
                                        </div>
                                    </div>

                                    <button
                                        onClick={handleWholesaleLogin}
                                        className='w-full font-lufga mt-8 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center '
                                    >

                                        Sign in
                                    </button>
                                    {loading ? (<div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                                        <InfinitySpin color="#387ADF" loading={loading} size={32} />
                                    </div>) : null}
                                    <div className='flex justify-between items-center font-lufga'>
                                        <Link to='/forgot-wholesale-pass'>
                                            <p className='hover:underline cursor-pointer text-sm text-blue-800'>Forgot Password?</p>
                                        </Link>
                                        <Link to='/signup'>
                                            <p className='hover:underline cursor-pointer text-sm text-blue-800'> Setup Account</p>
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        ) : (
                            <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
                                <form className='space-y-4 md:space-y-6' >
                                    {retailError && (
                                        <div>
                                            <p className="bg-red-300 font-lufga rounded-md shadow-md px-2 py-1 text-sm ">
                                                {typeof retailError === 'string' ? retailError : (
                                                    Object.keys(retailError).map((key) => (
                                                        Array.isArray(retailError[key]) ? retailError[key][0] : retailError[key]
                                                    ))
                                                )}
                                            </p>
                                        </div>
                                    )}
                                    <div>
                                        <label
                                            htmlFor='email'
                                            className='block mb-2 font-lufga text-sm font-medium text-gray-900 '
                                        >
                                            Email
                                        </label>
                                        <input
                                            value={retail_email}
                                            onChange={(e) => setRetail_email(e.target.value)}
                                            type='email'
                                            name='email'
                                            id='email'
                                            className='bg-gray-50 border border-gray-300 text-gray-900 text-xs sm:text-sm rounded-lg focus:outline-none  block w-full p-2.5 '
                                            placeholder='retailadmin@company.com'
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label
                                            htmlFor='password'
                                            className='block font-lufga mb-2 text-sm font-medium text-gray-900 '
                                        >
                                            Password
                                        </label>
                                        <div className='flex justify-between items-center bg-gray-50 border text-xs border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600  w-full p-2.5'>
                                            <input
                                                value={retail_password}
                                                onChange={(e) => setRetail_password(e.target.value)}
                                                type={passwordState ? 'text' : 'password'}
                                                name='password'
                                                placeholder='••••••••'
                                                className='bg-gray-50 focus:outline-none w-full'
                                                required
                                            />
                                            {passwordState ? (
                                                <IoEyeOutline size={20} onClick={handlePasswordToggle} />
                                            ) : (
                                                <FaRegEyeSlash size={20} onClick={handlePasswordToggle} />
                                            )}
                                        </div>
                                    </div>

                                    <button
                                        onClick={handleRetailLogin}
                                        className='w-full mt-8 font-lufga text-white bg-green-500 hover:bg-green-600 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center '
                                    >
                                        Sign in
                                    </button>
                                    {loading ? (<div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                                        <InfinitySpin color="#9BCF53" loading={loading} size={32} />
                                    </div>) : null}
                                    <div className='flex justify-between font-lufga'>
                                        <Link to='/forgot-retail-pass'>
                                            <p className='hover:underline cursor-pointer text-sm text-blue-800'>Forgot Password?</p>
                                        </Link>
                                        <Link to='/signup'>
                                            <p className='hover:underline cursor-pointer text-sm text-blue-800'> Setup Account</p>
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default LoginForm;
