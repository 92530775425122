import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FaUser, FaEnvelope, FaPhone, FaBuilding, FaSave, FaTimes } from 'react-icons/fa';
import { api_url } from '../../config';
import api from '../../utils/api';
import { InfinitySpin } from 'react-loader-spinner';
import { toast } from 'react-toastify';

const EditProfile = ({ onCancel, onSave ,data}) => {
  const { id, token } = useSelector((state) => state.auth);
  const [loader,setLoader] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company_name: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setFormData(data)
  }, [id]);

 

  const handleChange = (e) => {
    setFormData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      const response = await api.put(api_url + `/api/v1/retailer/admin/${id}/update-profile`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      onSave(response.data.data);
      toast.success('Profile updated successfully')
    } catch (error) {
      setError('Failed to update profile');
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

 

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
      <div className="w-full max-w-md rounded-xl shadow-xl bg-white overflow-hidden">
        <div className="bg-gradient-to-r from-green-300 to-green-700 text-white py-4 px-6">
          <h2 className="text-2xl font-semibold">Edit Profile</h2>
        </div>
        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          <InputField
            icon={<FaUser />}
            name="name"
            label="Name"
            value={formData.name}
            onChange={handleChange}
          />
          <InputField
            icon={<FaEnvelope />}
            name="email"
            label="Email"
            type="email"
            value={formData.email}
            onChange={handleChange}
          />
          {/* <InputField
            icon={<FaPhone />}
            name="phone"
            label="Phone"
            value={formData.phone}
            onChange={handleChange}
          /> */}
          <InputField
            icon={<FaBuilding />}
            name="company_name"
            label="Company Name"
            value={formData.company_name}
            onChange={handleChange}
          />
          {/* <button className='px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-300 '>Update Password</button> */}
          {error && <p className="text-red-900 text-sm bg-red-300 rounded-md shadow-sm text-center py-2">{error}</p>}
          <div className="flex justify-end space-x-4 mt-6">
            <button
              type="button"
              onClick={onCancel}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition duration-300 flex items-center"
            >
              <FaTimes className="mr-2" /> Cancel
            </button>
            <button
              type="submit"
              disabled={loader}
              className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-300 flex items-center"
            >
              {!loader ? ` Save Changes` :  `Saving...`}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const InputField = ({ icon, name, label, value, onChange, type = "text" }) => (
  <div className="relative">
    <label htmlFor={name} className="text-sm text-gray-600 mb-1 block">
      {label}
    </label>
    <div className="flex items-center border rounded-md">
      <span className="text-gray-500 px-3">{icon}</span>
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        className="flex-grow p-2 rounded-r-md focus:outline-none "
      />
    </div>
  </div>
);

export default EditProfile;