import React from 'react';
import BranchOverview from '../../components/wholesaleLib/BranchOverview';

const AdminDashboardPage = () => {
    return (
        <div className='flex gap-4'>
            <BranchOverview/>
        </div>
    );
}

export default AdminDashboardPage;
